<template>
  <Banner bg-image="gray-bg.jpg" talk-img="myth.png">
    <template v-slot:title>Suicide myths</template>
  </Banner>
  <section class="pt-10 gradient-white md:pt-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mt-8">
        <div class="grid grid-cols-1 gap-4">
          <div class="myth-block">
            <div class="myth-ttl">
              <span class="font-bold b1">Myth: </span>
              <span>Asking for help is a sign of weakness.</span>
            </div>
            <div class="myth-desc">
              <span class="font-bold b1">Fact: </span>
              <span
                >Asking for help is a sign of strength. Asking for help is not
                easy. By asking for help, we are making ourselves vulnerable to
                others and this takes immense strength. Not only does reaching
                out for help require strength but so too does speaking with
                someone openly about your mental health issues. Asking for help
                can be extremely difficult, especially when it comes to matters
                of our mental health. This is because of the long-standing
                stigma held in our society about mental health and particularly,
                suicide.</span
              >
            </div>
          </div>
          <div class="myth-block">
            <div class="myth-ttl">
              <span class="font-bold b1">Myth:</span>
              <span
                >Considering or planning suicide is a sign of something bad
                about you.</span
              >
            </div>
            <div class="myth-desc">
              <span class="font-bold b1">Fact: </span>
              <span
                >Many people experience hard and even very painful times in life
                for many different reasons. There is nothing inherently bad
                about you just because you found yourself considering or
                planning suicide. We know it’s very hard to talk about mental
                health challenges or very difficult and painful feelings and
                many people are concerned about how others will perceive them or
                respond to them if they ask for help. To reduce the stigma
                around mental health and suicide, we need to talk about these
                subjects openly and let people know they don’t need to feel
                shame about the feelings they are having. Whatever your thoughts
                or feelings, you are a valuable and worthwhile person and your
                life has value: you deserve help and you don’t have to be stuck
                with your feelings.</span
              >
            </div>
          </div>
          <div class="myth-block">
            <div class="myth-ttl">
              <span class="font-bold b1">Myth:</span>
              <span
                >It’s best to keep someone’s suicidal feelings a secret.</span
              >
            </div>
            <div class="myth-desc">
              <span class="font-bold b1">Fact: </span>
              <span
                >Your friend or family member telling you about their suicidal
                thoughts and feelings may have been incredibly difficult. It
                takes a lot of courage to share one’s thoughts about dying with
                another. You may want to keep their confidential information or
                respect their wishes. You may be unsure what to do with this
                information, it is always important to get help and support. Do
                not keep someone suicidal thoughts and feelings a secret – even
                if you are asked to do so.</span
              >
            </div>
          </div>
          <div class="myth-block">
            <div class="myth-ttl">
              <span class="font-bold b1">Myth:</span>
              <span
                >People who talk about suicide are only trying to get attention.
                They won’t really do it.</span
              >
            </div>
            <div class="myth-desc">
              <span class="font-bold b1">Fact: </span>
              <span
                >WRONG! Few people die by suicide without first letting someone
                else know how they feel. Those who are considering suicide give
                clues and warnings as a cry for help. In fact, most seek out
                someone to help them. It has been said that over 60% of
                individuals who do threaten to carry out a suicide either make
                an attempt or complete the act.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <FooterCard
    img="help-for-someone-else-braid.png"
    text="Explore tools and resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>
      <p class="mt-2.5">
        Here are some tools and resources you can lean on now to help keep
        yourself safe.
      </p>
    </template>
    <template v-slot:content>&nbsp; </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "SuicideMyths",
  components: {
    Banner,
    FooterCard,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}

.myth-ttl {
  background: #3c5eaa;
  color: #fff;
  font-size: 20px;
  padding: 15px 60px;
  display: flex;
}
.myth-desc {
  display: flex;
  padding: 15px 15px;
  background: #f4f6fa;
}
.b1 {
  flex: 0 0 60px;
  margin-right: 15px;
}
@media screen and (min-width: 560px) {
  .myth-desc {
    padding: 15px 60px;
  }
}
</style>
